import React, { SVGProps, useId } from 'react';

export const MagicAi = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 2 }, (_, i) => `${id}-${i}`);

  return (
    <svg {...props} viewBox="0 0 24 24">
      <g clipPath={`url(#${gradientIds[0]})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0C5.37258 0 0 5.37258 0 12C0 15.6108 1.59477 18.8491 4.11837 21.049C3.47478 21.2178 3 21.8034 3 22.5C3 23.3284 3.67157 24 4.5 24H19.5C20.3284 24 21 23.3284 21 22.5C21 21.8034 20.5252 21.2178 19.8816 21.049C22.4052 18.8491 24 15.6108 24 12C24 5.37258 18.6274 0 12 0ZM9 13.5C8.17157 13.5 7.5 14.1716 7.5 15C7.5 15.8284 8.17157 16.5 9 16.5C9.82843 16.5 10.5 15.8284 10.5 15C10.5 14.1716 9.82843 13.5 9 13.5ZM13.5 12C13.5 12.8284 14.1716 13.5 15 13.5C15.8284 13.5 16.5 12.8284 16.5 12V10.5H18C18.8284 10.5 19.5 9.82843 19.5 9C19.5 8.17157 18.8284 7.5 18 7.5H16.5V6C16.5 5.17157 15.8284 4.5 15 4.5C14.1716 4.5 13.5 5.17157 13.5 6V7.5H12C11.1716 7.5 10.5 8.17157 10.5 9C10.5 9.82843 11.1716 10.5 12 10.5H13.5V12Z"
          fill={`url(#${gradientIds[1]})`}
        />
      </g>
      <defs>
        <linearGradient
          id={gradientIds[1]}
          x1="18"
          y1="3.41978e-08"
          x2="11.8022"
          y2="23.9488"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <clipPath id={gradientIds[0]}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
