import React, { SVGProps, useId } from 'react';

export const Document = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();

  return (
    <svg viewBox="0 0 16 16" {...props}>
      <g clip-path={`url(#${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 13C1.5 14.6568 2.84315 16 4.5 16H11.5C13.1569 16 14.5 14.6568 14.5 13V2.99995C14.5 1.3431 13.1569 -4.57764e-05 11.5 -4.57764e-05H4.5C2.84315 -4.57764e-05 1.5 1.3431 1.5 2.99995V13ZM4.19495 4.49951C4.19495 4.00245 4.59789 3.59951 5.09495 3.59951L10.9008 3.59949C11.3978 3.59949 11.8008 4.00243 11.8008 4.49949C11.8008 4.99654 11.3978 5.39949 10.9008 5.39949L5.09495 5.39951C4.59789 5.39951 4.19495 4.99656 4.19495 4.49951ZM5.09495 7.19951C4.59789 7.19951 4.19495 7.60246 4.19495 8.09951C4.19495 8.59657 4.59789 8.99951 5.09495 8.99951H7.1C7.59706 8.99951 8 8.59657 8 8.09951C8 7.60246 7.59706 7.19951 7.1 7.19951H5.09495Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
